.root{
    padding: 10px;
    max-width: 800px;
    margin: auto;
    overflow: scroll;
    height: 100%;
    *{
        font-family: 'M PLUS 1p', sans-serif !important;
    }
    h1{
        font-size: 3em;
    }
    h2{
        font-size: 2em;
    }
    p{
        font-size: 1em;
    }
    ul{
        padding-left: 3em;
    }
    li{
        margin-bottom: 3em;
    }
    .date{
        text-align: right;    
    }

    .logo{
        width: 40%;
        max-width: 200px;
    }
    .sceenshots{
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(3, 1fr);
        .item{
            img{
                width: 100%;
            }
        }
    }
}
@media only screen and (max-width: 600px) {
    .root{
        .sceenshots{
            grid-template-columns: repeat(2, 1fr) !important;
        }
    }
}