.root{

    font-family: Poppins, "Helvetica Neue", Helvetica, Arial, "Hiragino Sans", ヒラギノ角ゴシック, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", Roboto, メイリオ, Meiryo, "ＭＳ Ｐゴシック", sans-serif;
    display: flex;
    flex-direction: column;
    height: 100%;


    @mixin _button{
        padding: 10px;
        border-radius: 6px;
        border: none;
        font-weight: 600;
        font-size: 18px;
        text-align: center;
        text-decoration: none;
        display: block;
        width: 100%;
        margin-bottom: 20px;

        &.secondary{
            border:1px solid #006BF9;
            color: #006BF9;
        }
        &.primary{
            background-color: #006BF9;
            color: #fff;
        }
    }
    button{
        @include _button;
    }

    .content{
        flex: 1;
        
        .title{
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            padding-top: 10px;
        }
        img{
            width: 100%;
        }

        span{
            display: block;
            padding: 20px;
        }
    }
    .bottom{
        padding: 20px;
        text-align: center;
    }

}