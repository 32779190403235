@mixin _pcscrollbar($dir:"y") {

    @if ($dir == "x") {
        overflow-x: overlay;
    } @else if ($dir == "y"){
        overflow-y: overlay;
    } @else {
        overflow: overlay;
    }


    @media only screen and (min-width: 500px) {

        &::-webkit-scrollbar {
            width: 7px;
            height: 7px;
        }
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: rgba(0,0,0,0.2);
        border-radius: 10rem;
        border: 1px solid #fff;
    }

    &::-webkit-scrollbar-track-piece:start {
        background: transparent;
    }

    &::-webkit-scrollbar-track-piece:end {
        background: transparent;
    }
}
