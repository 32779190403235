
@mixin _button{
    background-color: #006BF9;
    color: #fff;
    padding: 5px 20px;
    border-radius: 6px;
    border: none;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
}


.header{
    position: relative;
    padding: 20px;
    .title{
        font-weight: 600;
        text-align: center;
    }
    .back_bt{
        position: absolute;
        left: 10px;
        top: 15px;
        color: #006BF9;
        border:1px solid #006BF9;
        background-color: #fff;
    }
}

.root
{
    padding: 20px 20px 100px;
    overflow: scroll;
    height: 100%;
    
    button{
        @include _button;
    
        &:focus{
            background-color: #006BF9;
        }
    }

    .list{
        .item{
            color: #222222;
            display: flex;
            text-decoration: none;
            font-size: 16px;
            align-items: center;
            position: relative;
            margin-bottom: 30px;
            font-weight: 300;
            svg{
                font-size: 26px;
            }

            .icon{
                margin-right: 10px;
            }

            .arrow{
                position: absolute;
                right: 0;
            }
        }

        hr{
            height: 1px;
            background-color: #d0d0d0;
            border: none;
            color: #d0d0d0;
        }
        .label{
            font-size: 20px;
            font-weight: 600;
            margin: 0 0 40px;
            text-align: center;
        }

        .button{
            color: #000;
            border:1px solid #000;
            border-radius: 8px;
            padding: 10px;
            text-align: center;
            width: 90%;
            display: block;
            margin: auto;
            text-decoration: none;
        }
    }

}

.unsubscribe_popup
{
    .checkbox{
        padding: 10px 20px;
    }

    .unsubscribe_guide{
        padding: 20px;
        margin-top: 20px;
        border-radius: 10px;
        background-color: #f0f0f0;
        .title{
            font-weight: bold;
            font-size: 14px;
        }
        .list{
            padding-left: 20px;
            li{
                font-size: 14px;
                margin-bottom: 10px;
            }
        }
    }
    .reason{
        padding: 5px 20px 0;
        .label{
            font-size: .85em;
            margin-bottom: .5em;
            white-space: nowrap;
            font-weight: 600;
            color: #999;
        }
    }
}