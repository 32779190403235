.root{
    padding: 1em;
    position: relative;
    &.hide{
        opacity: 0;
        pointer-events: none;
    }

    .content{

        &.list_mode{
            padding-top: 2.5em;
        }
        &.search_mode{
            padding-top: 2em;
        }

        .bar{
            position: absolute;
            top: 15px;
            left: 0;
            right: 0;
            margin: auto;
            border: none; 
            border-radius: 100px;
            height: 4px;
            width: 30px;
            background-color: rgb(208, 208, 208);
        }
        
        .tabs{
            display: flex;
            width: 100%;
            justify-content: space-around;
            align-items: center;
            padding: 0 1em;
            .bt{
                padding: 8px 0;
                flex: 1;
                text-align: center;
                border-top: 1px solid #ddd;
                color: #888;
                &.selected {
                    border-top: 1px solid #000;
                    color: rgb(36, 138, 255);
                }
            }
        }
        .shops{
            display: flex;
            white-space: nowrap;
            overflow: scroll;
            width: 100%;
            padding: 1em;
            margin-bottom: 1em;
            .item{
                margin-right: .7em;
                width: 3.5em;
                text-decoration: none;
                color: #000;
                img{
                    width: 3.5em;
                    height: 3.5em;
                    border-radius: 100em;
                    display: block;
                    padding: 2px;
                    border: 1px solid #ccc;
                }
                .name{
                    font-size: .7em;
                    overflow: hidden;
                    text-align: center;
                }
            }
        }
        .tab{
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding: 0 1em;
            background-color: #1BA0E5;
            color: #fff;
            font-size: 1em;
            font-weight: 600;
            margin-bottom: 1.5em;
            > :first-child{
                border-right: 1px solid #fff;
                padding: 1em;
            }
        }
        .cate_list{
            display: flex;
            white-space: nowrap;
            overflow: scroll;
            width: 100%;
            padding: 1em;
            margin-bottom: 1em;
            .item{
                margin-right: 1em;
                width: 4.2em;
                text-decoration: none;
                color: #000;
                border-bottom: 2px solid #fff;

                img{
                    width: 3.2em;
                    height: 3.2em;
                    display: block;
                    padding: 2px;
                    margin: auto;
                }
                .name{
                    font-size: .7em;
                    text-align: center;
                }
                &.selected{
                    color: rgb(36, 138, 255);
                    font-weight: bold;
                    border-bottom: 2px solid rgb(36, 138, 255);
                }
            }
        }
        .list{
            // display: flex;
            // flex-wrap: wrap;
            padding-bottom: 20px;
            margin: auto;
            width: 96%;
            justify-content: center;
            flex-shrink: 0;
            align-self: auto;
            position: relative;
            content: "";
            display: table;
            clear: both;
            
            .no_result{
                display: flex;
                justify-content: center;
                min-height: 20vh;
                align-items: center;
            }
            .item{
                width: 33.3%;
                float: left;

                &.video{
                    float: right;
                    height: calc((96vw / 3) * 2 + 1px);
                }
                &.video_only{
                    float: left;
                }

                img, video{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: block;
                }
            }

            &.menu {
                display: block;
                .item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    border:1px solid #ddd;
                    overflow: hidden;
                    margin-bottom: 1em;
                    border-radius: 8px;
                    width: 100%;
                    text-decoration: none;
                    color: #000;
                    min-height: 100px;
                    width: 100%;
                    float: none;
                    
                    .img{
                        flex: 1;
                        min-width: 30%;
                        max-width: 300px;
                    }
                    .info{
                        overflow: hidden;
                        width: 100%;
                        padding: 1em;
                        .name, .cate, .address, .time {
                            text-align: center;
                            margin-bottom: 0.25em;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            width: inherit;
                        }
            
                        .name {
                            font-weight: bold;
                            font-size: 1em;
                        }
            
                        .cate, .address, .time {
                            font-size: 0.8em;
                        }
                    }
                }
            }
        }
        .more{
            background-color: #fff;
            border-radius: 1000px;
            padding: 1em 2.5em;
            margin: auto;
            width: fit-content;
            margin-top: 2em;
            margin-bottom: 2em;
            box-shadow: 0 5px 10px rgba(0,0,0,.16);
            font-size: .8em;
        }
    }
    

    .bottom_sheet{
        padding-top: calc(100vh - 10em);
        background: none;
        pointer-events: none;
        
        >div{
            background-color: #fff;
            box-shadow: 0 0 25px rgba(0,0,0,.3);
            pointer-events: all;
            min-height: 100vh;
                overflow: scroll;    // iphone,safari pointer-events対策
                >div{
                    width: calc(100% + 1px);    // iphone,safari pointer-events対策
                }
        }
    }

}

.video_popup [role=dialog]{

    padding: 1em;
    border-radius: 2em;
    .header{
        display: flex;
        align-items: center;
        text-decoration: none;
        img{
            width: 3em;
            height: 3em;
            border-radius: 100em;
            display: block;
            padding: 2px;
            border: 1px solid #ccc;
            margin-right: 1em;
        }
        .name{
            font-weight: 900;
            color: #000;
        }
    }
    video {
        width: 100%;
        height: 60vh;
    }
}
