
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@600;700;800;900&display=swap');

.root {
    overflow: scroll;
    height: 100%;
    
    font-family: "Noto Sans JP", sans-serif;
    font-optical-sizing: auto;
    

    .header {
        text-align: center;
        background-color: #f0f0f0;
        position: relative;
        .headerImage {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
        }

        .box{
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: 100000;
            top: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .logo {
                font-size: 24px;
                font-weight: bold;
                background-color: #fff;
                border-radius: 1000px;
                padding: .5em 1.2em;
                width: fit-content;
                margin: 1em auto;
                img {
                    width: 45vw;
                    max-width: 400px;
                    height: auto;
                    display: block;
                }
            }
            .title{
                font-size: 24px;
                font-weight: bold;
                margin-bottom: 10px;
                color: #fff;
                text-shadow: 0 0 2px rgba(0, 0, 0, 1);
            }
        }
        
    }

    .subTitle{
        padding: 1em 0;
        background: radial-gradient(92.02% 92.02% at 50% 51.59%, #36D7FF 0%, #00A6D0 100%);
        text-align: center;
        .text1{
            font-size: 1em;
            font-weight: bold;
            color: #fff;
        }
        .text2{
            font-size: 1.5em;
            font-weight: 900;
            color: #FFFF00;
        }
    }

    .search {
        margin: 20px 0;
        text-align: center;
        padding: 1em;
        .searchTitle {
            font-size: 1.2em;
            margin-bottom: 10px;
            font-weight: 900;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                height: 20px;
                display: block;
                margin: 0 10px;
                &:last-child{
                    transform: scale(-1, 1);
                }
            }
        }
        .searchButton {
            display: block;
            text-decoration: none;
            background: #02A8D2;
            box-shadow: 0px 4px 0px #00728E;
            border-radius: 5px;

            color: #fff;
            padding: 10px 20px;
            cursor: pointer;
            margin: 1em 0;
            font-size: 1.2em;
            font-weight: 900;
        }
        .quickButton {
            display: block;
            text-decoration: none;
            background: #ffa600;
            box-shadow: 0px 4px 0px #d59c00;
            border-radius: 5px;
            color: #fff;
            padding: 10px 20px;
            margin: 1em 0;
            font-size: 1.2em;
            font-weight: 900;
        }

        .telButton {
            display: flex;
            text-decoration: none;
            box-shadow: 0px 4px 0px #008fc8;
            border-radius: 5px;
            margin: 1em 0;
            overflow: hidden;
            div{
                &:nth-child(1){
                    background: #00abee;
                    color: #fff;
                    padding: 1em;
                    width: 50%;
                    font-size: .8em;
                }
                &:nth-child(2){
                    background: #fff;
                    color: #00abee;
                    font-size: 1.4em;
                    font-weight: 900;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50%;
                }
            }
        }

        .mailButton {
            display: flex;
            text-decoration: none;
            box-shadow: 0px 4px 0px #008fc8;
            border-radius: 5px;
            margin: 1em 0;
            overflow: hidden;
            div{
                &:nth-child(1){
                    background: #00abee;
                    color: #fff;
                    padding: 1em;
                    width: 50%;
                    font-size: .8em;
                }
                &:nth-child(2){
                    background: #fff;
                    color: #008fc8;
                    font-size: .9em;
                    font-weight: 900;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50%;
                }
            }
        }

        .searchInput {
            width: 100%;
            margin-top: 10px;
            border: 1px solid #ccc;
            border-radius: 10px;
            display: flex;
            align-items: center;
            background-color: #fff;
            .icon {
                height: 60px;
                display: block;
            }
            input{
                border: none;
                outline: none;
                width: 100%;
                font-size: 1em;
                padding: 1em;
            }
        }
    }

    .section {
        text-align: center;

        h2 {
            font-size: 1.25em;
            font-weight: 900;
        }

        .regions {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 0; /* グリッドアイテム間のスペースを0に設定 */
        
            a {
                padding: 20px;
                // border-left: 1px solid #ccc;

                border-radius: 0;
                background-color: #fff;
                color: black;
                cursor: pointer;
                font-size: 1.2em;
                font-weight: 600;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                border-right: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
                &:nth-child(1), &:nth-child(2) {
                    border-top: 1px solid #ccc;
                }
            }
        }
    }

    
    .features {
        padding-top: 3em;
        background-color: #fff;
        h2 {
            width: 100%;
            text-align: center;
            font-size: 1.25em;
            font-weight: 900;
            margin-top: 0;
            margin-bottom: 20px;
            
            span {
                font-size: 1.5em;
            }
        }

        .feature {
            border-bottom: 5px solid #ccc;
            padding: 3em 2em 1em;
            
            h3 {
                margin: 10px 0;
                font-size: 1.2em;
                font-weight: 900;
                border-radius: 1000px;
                color: #fff;
                width: 70%;
                margin: 0 auto;
                padding: .3em;
                text-align: center;
            }
            h4{
                margin: .5em 0;
                font-size: 1.2em;
                font-weight: 900;
                text-align: center;
            }

            p {
                margin: 5px 0;
                font-size: .9em;
            }

            img{
                height: 130px;
                display: block;
                margin: 2em auto 0;
            }

            &:nth-child(odd) {
                background-color: #FFFDF0;
            }


            &:nth-child(2) {
                border-bottom-color: #1BA0E5;
                h3 {
                    background-color: #1BA0E5;
                }
                h4{
                    color: #1BA0E5;
                }
            }
            &:nth-child(3) {
                border-bottom-color: #5F6FA2;
                h3 {
                    background-color: #5F6FA2;
                }
                h4{
                    color: #5F6FA2;
                }  
                
                img{
                    height: 100px;
                } 
            }

            &:nth-child(4) {
                border-bottom-color: #F0A953;
                h3 {
                    background-color: #F0A953;
                }
                h4{
                    color: #F0A953;
                }
                img{
                    height: 100px;
                } 
            }
            &:nth-child(5) {
                border-bottom-color: #009E55;
                h3 {
                    background-color: #009E55;
                }
                h4{
                    color: #009E55;
                }
            }
            &:nth-child(6) {
                border-bottom-color: #FF6868;
                h3 {
                    background-color: #FF6868;
                }
                h4{
                    color: #FF6868;
                }
                img{
                    height: 100px;
                } 
            }

        }
    }

    .steps {
        
        padding: 20px;

        h2 {
            width: 100%;
            text-align: center;
            font-size: 1.25em;
            font-weight: 900;
            margin-bottom: 50px;
        }

        .step {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin: 10px;
            padding-bottom: 3em;
            background-image: url('./assets/home-step-bg.png');
            background-size: 5px 100%;
            background-repeat: no-repeat;
            background-position: 16px 0;
            
            &:last-child {
                background: none;
            }

            .number {
                display: block;
                font-size: 1.6em;
                font-weight: 900;
                background-color: #1BA0E5;
                width: 38px;
                height: 38px;
                border-radius: 1000px;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                outline: 3px solid #fff;
            }
            .icon{
                display: block;
                margin-right: 1em;
                width: 70px;
                height: 90px;
            }
            .titleBox {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                flex: 1;
                .title{
                    font-size: 1.2em;
                    font-weight: 900;
                    margin-bottom: .5em;
                }
                .text{
                    font-size: 14px;
                }
            }
            
        }
    }

    .qa {
        padding: 2em 1em;
        background-color: #FFFDF0;

        h2 {
            margin: 0;
            font-size: 1.25em;
            font-weight: 900;
            text-align: center;
            margin-bottom: 1em;
        }

        .qaBox {
            
            .item{
                padding: 1em;
                .q{
                    font-size: 1em;
                    font-weight: 600;
                    margin-right: 1em;
                    &:before{
                        content: "Q";
                        color: #00a2ff;
                        padding: .2em .5em;
                        display: inline-block;
                        margin-right: .1em;
                        font-size: 1.3em;
                        font-weight: 900;
                        display: inline-block;
                    }
                }
                .a{
                    font-size: .9em;
                    &:before{
                        content: "A";
                        color: #00a2ff;
                        padding: .2em .5em;
                        display: inline-block;
                        margin-right: .5em;
                        font-size: 1.2em;
                        font-weight: 900;
                        display: inline-block;
                    }
                }
            }
        }
    }
}