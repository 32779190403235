*{
    word-wrap: break-word;
}

.root{

    h1{
        font-size: 24px;
    }
    h2{
        font-size: 16px;
    }
    ul{
        padding-left: 20px;
    }
    li{
        margin-bottom: 20px;
    }
    .date{
        text-align: right;    
    }
}