@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@600;700;800;900&family=Poppins:wght@700&display=swap');

.root {
    overflow: scroll;
    height: 100%;
    font-family: "Noto Sans JP", sans-serif;
    font-optical-sizing: auto;
    padding: 2em 0 0;

    /* Header */
    .header {
        display: flex;
        align-items: center;
        background-color: #fff;
        padding: 10px;
        border-bottom: 1px solid #ddd;

        .backButton {
            color: #000;
            text-decoration: none;
            margin-right: 1em;
            display: flex;
            align-items: center;
            justify-content: center;
            svg{
                display: block;
            }
        }

        .title {
            font-size: 24px;
            font-weight: bold;
            color: #000;
            margin: 0;
            margin-right: .5em;
            display: block;
        }

        .subTitle {
            font-size: 14px;
            margin: 0;
            background-color: #F4F4F4;
            color: #7F7F7F;
            padding: 0.3em .75em;
            border-radius: 1000px;
            font-weight: 600;
            display: block;
        }
    }

    /* Main Image */
    .mainImage {
        width: 100%;
        img {
            width: 100%;
            height: auto;
        }
    }

    /* Thumbnail Slider */
    .thumbnailSlider {
        display: flex;
        align-items: center;
        overflow: scroll;
        img {
            width: 100px;
            height: auto;
            margin-right: 5px;
        }
    }

    /* Location */
    .location {
        background-color: #fff;
        padding: 20px;
        border-bottom: 1px solid #ddd;

        h2 {
            font-size: 18px;
            color: #000;
            margin-bottom: 10px;
        }

        .postalCode {
            font-size: 14px;
            color: #000;
            margin-bottom: .5em;
        }

        .address {
            font-size: 14px;
            color: #000;
            margin-bottom: .5em;
        }

        .map{

            width: 100%;
            height: 300px;
            font-weight: bold;
            iframe{
                border: none;
            }
        }
    }

    /* Plan */
    .plan {
        background-color: #00AEEF;
        padding: 20px;
        border-radius: 10px;
        text-align: center;
        

        .planItem {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #fff;
            padding: 1em;
            border-radius: 10px;
            text-align: center;


            .planTitle {
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 2em;
                font-weight: 900;
            }

            .planSubTitle {
                font-size: .8em;
            }
            .option{
                display: flex;
                gap: 1em;

                .planImage {
                    width: 65%;
                    height: auto;
                    margin-bottom: 20px;
                    display: block;
                    object-fit: cover;
                }

                .serviceList {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    margin-bottom: 20px;

                    .serviceItem {
                        display: flex;
                        align-items: center;
                        margin-bottom: .5em;
                        padding: .5em .5em;
                        border-radius: .5em;
                        width: 100%;
                        font-size: .9em;
                        font-weight: 600;
                        background-color: #D5D5D5;
                        color: #8F8F8F;

                        .serviceIcon {
                            margin-right: 10px;
                            font-size: 1.2em;
                        }

                        &.active {
                            background-color: #EBEBEB;
                            color: #000;
                            .serviceIcon {
                                color: #02A8D2;
                            }
                        }
                    }
                }
            }

            .price{
                position: relative;
                display: flex;
                gap: 1em;
                margin: 1.5em 0;
                &::before{
                    content: "";
                    position: absolute;
                    bottom: .5em;
                    left: 0;
                    right: 0;
                    border-bottom: 10px solid #FADF44;
                    display: flex;
                    gap: 1em;
                    z-index: 0;
                }

                .num {

                    z-index: 1;
                    span{
                        font-size: 3.5em;
                        font-family: "Poppins", sans-serif;
                        font-weight: 700;
                        letter-spacing: -4px;
                        line-height: 1em;
                    } 
                }

                .tax {

                    z-index: 1;
                    small{
                        font-size: .7em;
                        display: block;
                        font-weight: 900;
                        margin: 0;
                    }
                    span{
                        font-size: 1.5em;
                        display: block;
                        font-weight: 900;
                        margin: 0;
                        line-height: 1em;
                    }
                }
            }

            .buttons {
                width: 100%;
                > div {
                    color: white;
                    padding: .75em;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                    font-size: 1.3em;
                    box-shadow: 0 .3em 0 #00728E;
                    margin-bottom: .75em;
                    font-weight: 600;
                }

                .addButton {
                    background-color: #02A8D2;
                }

                .requestButton {
                    background-color: #2CC6ED;
                }
            }
        }

        h2 {
            font-size: 24px;
            margin-bottom: 1.5em;
            color: white;
        }

    }

    /* Basic Info */
    .basicInfo {
        background-color: #fff;
        padding: 20px;
        border-top: 1px solid #ddd;

        h2 {
            font-size: 18px;
            color: #000;
            margin-bottom: 10px;
            text-align: center;
        }

        .info{
            font-size: .9em;
            color: #666;
            margin-bottom: 2em;
        }

        .basicInfoTable {
            width: 100%;
            border-collapse: collapse;
            > tbody {
                > tr {
                    > td {
                        border: 1px solid #ddd;
                        padding: 10px;
                        font-size: 14px;
                        color: #000;

                        &:first-child {
                            background-color: #F4F4F4;
                        }
                    }
                }
            }
        }
        .basicInfoTableOther {
            padding: 0 !important;
            border: none !important;
            table{
                width: 100%;
                border-collapse: collapse;
                > tbody {
                    > tr {
                        > td {
                            border-right: 1px solid #ddd;
                            border-bottom: 1px solid #ddd;
                            padding: 10px;
                            font-size: 14px;
                            color: #000;

                            &:last-child {
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }
}