body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;


    /* テキストの選択禁止 */
    user-select: none;
    /* リンク長押しのポップアップを無効化 */
    -webkit-touch-callout:none;
    /* テキスト長押しの選択ボックスを無効化 */
    -webkit-user-select:none;

    /* タップした時の色 */
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 

    /* 親スクロールが反応して画面が固まる事を防ぐ */
    height: 100vh;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*{
    box-sizing: border-box;
    
    /* ダブルタップズームを禁止 */
    touch-action: manipulation;
}

/* 警告の非表示 */
.firebase-emulator-warning
{
    pointer-events: none;
    animation: 1s linear 1s firebase-emulator-warning-anim both;
}
@keyframes firebase-emulator-warning-anim {
    from { opacity: 1; }
    to   { opacity: 0; }
}