@import './lib/PCScrollBar.module.scss';

.root{

    @include _pcscrollbar;
    height: 100%;

        
    .box{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        > div{
            .container{
                padding-left: 1em;
                padding-right: 1em;
            }
            padding-bottom: 60px;
            > ul{
                li{
                    background-color: rgb(37, 180, 241) !important;
                }
            }
        }
        
        .slide1{
            font-weight: 900;
            font-size: 1.5em;
            img{
                width: 20%;
                margin-bottom: 2em;
            }
        }
        
    }

    .bt_container{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 1em;
        padding: 1.5em;
        text-align: center;

        .search_shop{
            display: flex;
            text-align: center;
            margin: auto;
            margin-bottom: .5em;
            width: 100%;
            height: 3em;
            border: none;
            border-radius: 0.3em;
            background-color: #ff6d6d;
            color: #fff;
            font-size: 1.2em;
            font-weight: 800;
            cursor: pointer;
            text-decoration: none;
            justify-content: center;
            align-items: center;
            &:hover{
                background-color: #333;
            }
            &:active{
                background-color: #999;
            }
        }

        hr{
            margin: 1.5em 0;
        }
        .submit{
            display: flex;
            text-align: center;
            margin: auto;
            width: 100%;
            height: 3em;
            border: none;
            border-radius: 0.3em;
            background-color: #000;
            color: #fff;
            font-size: 1.2em;
            font-weight: 800;
            cursor: pointer;
            text-decoration: none;
            justify-content: center;
            align-items: center;
            &:hover{
                background-color: #333;
            }
            &:active{
                background-color: #999;
            }
        }
        a{
            display: block;
            margin-top: 1em;
            text-decoration: none;
            color: #1596ff;
        }
    }


}