@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@600;700;800;900&display=swap');

.root{
    font-family: "Noto Sans JP", sans-serif;
    background-color: #707070;
    color: #fff;
    padding: 1em 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1em;
    .num{
        font-size: 1.5em;
        font-weight: 600;
        span{
            font-size: 1.5em;
            font-weight: 600;
            color: #ffbb00;
        }
    }
    .bt{
        background-color: #02A8D2;
        box-shadow: 0 .3em 0 #00728E;
        color: #fff;
        padding: 1em 1.5em;
        border-radius: 1em;
        font-weight: 900;
        font-size: 1.1em;
        text-decoration: none;
    }

}