.bottom {
    position: absolute;
    bottom: 50px;
    left: 10px;
    right: 10px;
    z-index: 9999;
    gap: 1em;
    display: flex;
    flex-direction: column;
    .row {
        display: flex;
        flex-direction: row;
        gap: 1em;
    }
}

.button {
    text-align: center;
    background: #f6fcff;
    color: #2b2b2b;
    font-size: .8em;
    padding: .8em;
    border-radius: .5em;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .icon{
        width: 1.5em;
        height: 1.5em;
        margin-right: .5em;
    }
}
.applyButton {
    text-align: center;
    background: #0096f7;
    color: #fff;
    font-size: 1em;
    font-weight: 900;
    padding: .8em;
    border-radius: .25em;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.mapContainer {
    position: relative;
    width: 100%;
    height: 100%;
}

.crosshair {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 1000;
    width: 40px;
    height: 40px;

    &::before,
    &::after {
        content: '';
        position: absolute;
        background-color: rgb(255, 0, 0);
    }

    &::before {
        width: 3px;
        height: 40px;
        left: 20px;
        transform: translateX(-50%);
    }

    &::after {
        width: 40px;
        height: 3px;
        top: 20px;
        transform: translateY(-50%);
    }
}

.zipDialog {
    display: flex;
    gap: 1em;
    flex-direction: row;
    padding: 1em;
}

.header {
    position: absolute;
    top: 10px;
    left: .5em;
    right: .5em;
    text-align: center;
    z-index: 9999;
    background: #fffab5;
    border-radius: 1em;
    padding: 1em;
    .backButton {
        position: absolute;
        right: 1em;
    }
}
