.root{

    font-family: Poppins, "Helvetica Neue", Helvetica, Arial, "Hiragino Sans", ヒラギノ角ゴシック, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", Roboto, メイリオ, Meiryo, "ＭＳ Ｐゴシック", sans-serif;
    display: flex;
    flex-direction: column;
    height: 100%;


    @mixin _button{
        background-color: #006BF9;
        color: #fff;
        padding: 5px 20px;
        border-radius: 6px;
        border: none;
        font-weight: 600;
        text-align: center;
        text-decoration: none;
    }
    button{
        @include _button;

        &:focus{
            background-color: #006BF9;
        }
    }

    .header{
        position: relative;
        padding: 20px;
        .title{
            font-weight: 600;
            text-align: center;
        }
        .save_bt{
            position: absolute;
            right: 10px;
            top: 15px;
        }
        .back_bt{
            position: absolute;
            left: 10px;
            top: 15px;
            color: #006BF9;
            border:1px solid #006BF9;
            background-color: #fff;
        }
    }
    .scroller{
        overflow: scroll;
        padding: 10px;

        .icon{
            width: 80px;
            height: 80px;
            border-radius: 1000px;
            object-fit: cover;
        }
        > .item{
            margin-top: 20px;

            > .label{
                font-weight: bold;
                font-size: 16px; 
                margin-bottom: 10px;
            }
            > .row{
                display: flex;
                align-items: center;
                > .text{
                    flex: 1;
                    font-size: 13px;
                }
            }
        }
    }
}