@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;600;700;800;900&display=swap');

.root{
    display: flex;
    flex-direction: column;
    height: 100%;
    .header{
        flex: 1;
        text-align: center;
        padding: 1em;
        .title{
            font-weight: 900;
            font-size: 3em;
            font-family: 'Noto Sans JP', sans-serif;
            line-height: 1em;
        }
        .subtitle{
            font-weight: 900;
            font-size: 1.5em;
            font-family: 'Noto Sans JP', sans-serif;
        }
        .closebt{
            position: absolute;
            right: 8px;
            top: 8px;
            font-weight: 900;
            cursor: pointer;
            box-shadow: 0 8px 10px rgba(0, 0, 0, .2);
            padding: .1em;
            font-size: 2em;
            border-radius: 100px;
        }
    }
    .content{
        display: flex;
        height: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        overflow: hidden;

        .item{
            position: relative;
            height: 50%;
            width: 50%;
            padding: .5em;
            overflow: hidden;
            .photo{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 1em;
            }
            .text{
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                display: block;
            }
        }
    }
}