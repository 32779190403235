@import './lib/PCScrollBar.module.scss';

.root{

    @include _pcscrollbar;
    height: 100%;
    
    
    .header{
        position: relative;
        
            

        .photo img{
            width: 100%;
        }

        .thumbs{
            display: flex;
            flex-direction: row;
            align-items: center;
            overflow: scroll;
            img{
                width: 33%;
                margin-right: .5em;
            }
        }
        > .infotext{
            padding: 2em 1em;
            margin-bottom: 1em;
            font-weight: 500;
            font-size: .9em;
            white-space: pre-wrap;
            width: 100%;
        }

        &:nth-child(2){
            width: 100%;
        }

        .text{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 1em;
            .name{
                font-weight: 600;
                font-size: 1.3em;
            }
            .desc{
                font-weight: 500;
                color: #919191;
                font-size: .9em;
            }
        }
        .payment_methods{
            padding: 0 1em;
            text-align: center;
            margin:auto;
            margin-bottom: 1em;

            .item{
                height: 2em;
                font-size: .7em;
                color: #666;
                border-radius: .3em;
                border:1px solid #999;
                padding: 4px 5px;
                display: inline-flex;
                align-items: center;
                margin-right: .2em;
                margin-bottom: .2em;
            }
        }

        .introduction{
            padding: 0 1em;
            margin-bottom: 1em;
            font-weight: 500;
            color: #555;
            font-size: .9em;
            white-space: pre-wrap;
            width: 100%;
        }

    }

    .area{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1em 0;

        .title{
            font-weight: 600;
            font-size: 1.3em;
            margin-bottom: .5em;
        }
        .desc{
            font-weight: 400;
            font-size: 1.0em;
            margin-bottom: 1em;
            padding: 0 2em;

            .more_all_time{
                color: #007bff;
                cursor: pointer;
                font-size: .8em;
                text-align: center;
            }
            .time{
                text-align: center;
            }
            .all_time{
                margin-top: 1em;
                white-space: pre-wrap;
                text-align: center;
            }
        }

        .map{

            width: 100%;
            height: 300px;
            font-weight: bold;
            iframe{
                border: none;
            }
        }

    }

    .contact{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1em 0;

        .title{
            font-weight: 600;
            font-size: 1.3em;
            margin-bottom: .5em;
        }
        .text{
            font-weight: 400;
            font-size: 1.3em;
            margin-bottom: 1em;
            padding: 0 2em;
            color: #007DFF;
        }
    }

    .plan{
        padding: 2em 1em;
        background-color: #1BA0E5;
        
        .title{
            text-align: center;
            font-weight: 600;
            font-size: 1.3em;
            margin-bottom: .5em;
            color: #fff;
        }
        img{
            width: 100%;
            border-radius: 1em;
            box-shadow: 0 5px 10px rgba(0,0,0,.16);
        }
    }

    .info{
        padding: 2em 0em;
        
        .title{
            text-align: center;
            font-weight: 600;
            font-size: 1.3em;
            margin-bottom: .5em;
        }
        img{
            width: 100%;
        }
    }

    .popular{
        padding:  1em;
        > .title{
            font-weight: 600;
            font-size: 1.3em;
            margin-bottom: 0.5em;
        }
        .list{
            width: 100%;
            overflow: scroll;
            display: flex;

            .item{
                margin-right: 1em;
                .icon{
                    width: 5em;
                }
                .text{
                    text-align: center;
                    .title{
                        font-weight: 900;
                        font-size: .9em;
                    }
                    .desc{
                        font-size: .8em;
                    }
                }
            }
        }
    }
    .pr_video{
        video{
            width: 100%;
            height: 60vh;
        }
    }

    .goto_bt{
        
        background-color: #007bff;
        border-radius: 1000px;
        padding: .5em 1.5em;
        position: sticky;
        z-index: 2;
        bottom: 3em;
        left: 0;
        right: 0;
        margin: auto;
        width: fit-content;
        box-shadow: 0 5px 10px rgba(0,0,0,.16);
        a{
            color: #fff;
            text-decoration: none;
            font-weight: 600;
        }
    }


    .sns_links{
        padding: 3em 0 1em;
        display: flex;
        justify-content: center;
        .item{
            img{
                width: 1.5em;
                height: 1.5em;
                opacity: .3;
                margin-right: .5em;
            }
        }
    }

    .links{
        padding: 0 0 5em;
        text-align: center;
        width: fit-content;
        margin: auto;
        max-width: calc(100% - 2em);
        .item{
            padding: 0 .5em;
            display: flex;
            align-items: center;
            text-decoration: none;
            color: #2a66f4;
            margin-bottom: .5em;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            
            img{
                width: 1.5em;
                height: 1.5em;
                opacity: .3;
                margin-right: .5em;
            }
        }
    }

    .fog{
        z-index: 1;
        position: sticky;
        height: 100px;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    }

    .menulist{
        .menu_desc{
            width: 100%;
            height: 3em;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;  
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .carousel_root{
        transform: translateZ(0);
        height: 150px; 
        overflow: hidden;
    }
}
.menu_popup [role=dialog]{
    border-radius: 3em;
    padding: 1em;
    width: 100%;
    .header{
        display: flex;
        margin-bottom: 1em;
        align-items: center;

        .icon{
            width: 26vw;
            margin-right: 1em;
            object-fit: contain;
            flex: 1;
        }
        .title{
            font-size: 1.5em;
            font-weight: 800;
        }
        .price{
            font-size: 1em;
            font-weight: 300;
        }
    }
    .text{
        font-size: .9em;
        font-weight: 300;
        padding: 0 0 1em;
    }
}

.calendar_popup [role=dialog]{
    border-radius: 1em;
    padding: 1em;
    width: 100%;

    .name{
        font-size: 1.5em;
        font-weight: 800;
        margin: .3em 0;
    }
    .address{
        font-size: 1em;
        font-weight: 300;
        margin-bottom: .2em;
    }
    .time{
        font-size: .9em;
        font-weight: 300;
        padding: 0 0 1em;
        margin-bottom: .2em;
    }
    .desc{
        font-size: .9em;
        font-weight: 300;
        padding: 0 0 1em;
        margin-bottom: .2em;
    }
    .url{
        font-size: .9em;
        font-weight: 300;
        padding: 0 0 1em;
        color: #007bff;
        text-decoration: underline;
        margin-bottom: .2em;
    }
}