.root{

    padding: 1em;

    .item{
        background-color: #fff;
        display: flex;
        box-shadow: 0px 0px .75em rgba(0, 0, 0, 0.16);
        margin-bottom: 2em;
        border-radius: 1em;
        text-decoration: none;
        color: #000;
        overflow: hidden;
        cursor: pointer;

        .icon{
            width: 5em;
            min-height: 5em;
            object-fit: cover;
            margin-right: 2em;
        }
        .text{
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;
            padding: .5em 0;

            .title{
                font-weight: 600;
                font-size: 1.3em;
            }
            .desc{
                font-weight: 500;
                color: #919191;
                font-size: .9em;
            }
        }
    }
}