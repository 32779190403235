@import './ShopList.module.scss';

.layout{
    transform: translateZ(0);
    height: 100%;

    .root{
        > div {
            width: 50%;
        }
        .listview_item{
            position: relative;
            div, img{
                pointer-events: none;
            }
            button{
                pointer-events: all;
            }
            .action_bt{
                position: absolute;
                right: 0;
                bottom: 0;
                .approval_bt, .ban_bt, .edit_bt, .delete_bt, .auth_change_bt{
                    background-color: #fff;
                    border-radius: 1em;
                }
            }
            .sales{
                padding: .5em 0;
                font-size: .8em;
                color: #555;
                .open{
                    color: green;
                }
            }
            .platform{
                position: absolute;
                top: 0;
                left: 0;
                background-color: #fff;
                padding: .2em;
                border-radius: 100em;
                display: flex;
                box-shadow: 0 .3em .3em rgba(0,0,0,.2);
            }
        }
    }

    .detail{
        position: fixed;
        width: 50%;
        top: 0;
        right: 0;
        height: 100%;
        overflow: scroll;
    }
}
