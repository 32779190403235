@import './Layout.module.scss';


@media only screen and (min-width: 500px) {

    .root {
        max-height: 800px;
        box-shadow: 0 20px 30px rgba(0, 0, 0, 0.18);
        border-radius: 20px;
    }
}

.copylight{
    display: none;
}

@media only screen and (min-height: 930px) and (min-width: 500px) {

    .copylight{

        display: block;
        font-size: 13px;
        color: #999;
        position: fixed;
        bottom: 40px;
        left: 0;
        right: 0;
        margin: auto;
        width: fit-content;
    }
}