@import './lib/PCScrollBar.module.scss';

.root{

    @include _pcscrollbar;
    height: 100%;
    
    .header{
        background-image: url('./recruit-bar.svg');
        background-repeat: repeat-x;
        background-size: 150px;
        background-position: bottom;
        padding-bottom: 3em;
        img{
            width: 100%;
        }

    }

    .intro{
        .text{
            padding: 3em 1.5em;
            line-height: 1.8em;
        }
    }

    .form{
        padding: 3em 1.5em;
        .item{
            margin-bottom: 2em;
            .label{
                font-size: .8em;
                font-weight: bold;
                margin-bottom: 0.5em;
            }
            .input{
                width: 100%;
                height: 2.5em;
                border: 1px solid #ccc;
                border-radius: 0.3em;
                padding: 0 1em;
                font-size: 1.2em;
                &:focus{
                    outline: none;
                    border-color: #61a3ff;
                }
                &::placeholder{
                    color: #999;
                }
            }
        }
    }

    .bt_container{
        padding: 1.5em;
        text-align: center;
        .submit{
            display: flex;
            text-align: center;
            margin: auto;
            width: 100%;
            height: 3em;
            border: none;
            border-radius: 0.3em;
            background-color: #000;
            color: #fff;
            font-size: 1.2em;
            font-weight: 800;
            cursor: pointer;
            text-decoration: none;
            justify-content: center;
            align-items: center;
            &:hover{
                background-color: #333;
            }
            &:active{
                background-color: #999;
            }
        }
        a{
            display: block;
            margin-top: 1em;
            text-decoration: none;
            color: #1596ff;
        }
    }
    

}