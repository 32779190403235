@mixin _arrow {
    position: absolute;
    top: calc(50% - 35px);
    width: 12%;
    max-width: 60px;
    z-index: 1;
    filter: drop-shadow(0 6px 10px rgba(0,0,0,0.4));
    opacity: 0.7;
    transition: all 1s;

    &.hide{
        opacity: 0.4;
        pointer-events: none;
    }
}
.arrow_r{
    @include _arrow; 
    right: 0;
    
}
.arrow_l{

    @include _arrow; 
    left: 0;
    transform: scaleX(-1);
}

.navi {
    display: flex;
    align-items: center;
    padding: 8px 20px;
    background-color: #eee;
    font-size: 12px;
    color: #777;
    
}

.backbt{
    background-color: #fff;
    display: block;
    padding: .5em;
    position: fixed;
    top: 1em;
    left: 1em;
    border-radius: 100px;
    width: 2.5em;
    height: 2.5em;
    z-index: 9999;
    svg{
        width: 100% ;
        height: 100% ;
        color: #707070;
    }
}