.root{

    position: fixed;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: #00000099;
    z-index: 999;
    padding-top: 50px;
    overflow: scroll;

    &.hide{
        opacity: 0;
        height: 100px;
        overflow: hidden;
    }

    .box{
        background-color: #F3F3F3;
        border-radius: 10px 10px 0 0;
        min-height: 100%;
    }
}
