@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@600;700;800;900&family=Poppins:wght@700&display=swap');


.root{
    *{
        font-family: "Noto Sans JP", sans-serif;
    }
    padding: 2em 1em 10em;
    overflow: scroll;
    height: 100%;
    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .backButton{
            margin-right: 1em;
        }
    }
    .title{
        text-align: center;
        margin-bottom: 2em;
        font-weight: 900;
        font-size: 1.2em;
    }
    .complete{
        font-size: 1em;
        font-weight: 400;
        span{
            font-weight: 700;
            font-size: 1.2em;
            display: block;
            margin-bottom: .5em;
        }
    }
    > .item{
        margin-bottom: 2em;
        .input{
            margin: 0;
        }
    }

    .itemTitle{
        margin-bottom: 0em;
        font-weight: bold;
        font-size: .9em;
    }

    .list{
        > .item{

        }
    }
    .button{
        font-size: 1.2em;
    }
}
