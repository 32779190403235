@import './lib/PCScrollBar.module.scss';

.root {

    background-color: #ff6d6d;
    @include _pcscrollbar;
    font-family: 'Noto Sans JP', sans-serif;
    overflow-y: scroll;
    height: 100%;
    hr {
        width: 100%;
        height: 1px;
        background-color: rgba(255, 255, 255, .5);
        border: none;
    }


    .pinkbt {
        background-color: #ff6d6d;
        border:1px solid #fff;
        color: #fff;
        font-weight: 900;
        text-align: center;
        border-radius: 100em;
        width: fit-content;
        margin: auto;
        padding: .5em 2em;
        font-size: 1.5em;
        text-decoration: none;
        display: block;
    }

    .sticky {
        position: sticky;
        display: block;
        top: 10px;
        z-index: 1;
        left: 0;
        right: 0;
    }


    .header {

        background-color: #fff;
        font-weight: 900;
        display: flex;
        align-items: center;
        padding: 2em;
        gap: 1em;

        .logo {
            display: block;
            width: 12%;
            margin-right: 1em;
        }

        .name {
            font-size: 1.1em;
        }

        .regist {
            flex: 1;
            text-align: center;
            font-size: 1.6em;
        }
    }

    .intro {
        background-color: #fff;
        padding-bottom: 4em;
        .photo {
            width: 100%;
            margin-bottom: 1em;
        }

        .pop {
            border-radius: 100em;
            border: 1px solid #C6C6C6;
            padding: 1em;
            color: #888;
            text-align: center;
            font-weight: 600;
            margin: 0 1em 1em;
        }

    }

    .what {
        padding: 1em;
        background-color: #fff;
        padding-bottom: 8em;

        .title {

            font-family: 'Noto Sans JP';
            font-style: normal;
            font-weight: 350;
            font-size: 23px;
            line-height: 54px;
            text-align: center;
            margin-bottom: 1em;
        }

        .text {
            font-family: 'Noto Sans JP';
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 36px;
            text-align: center;
        }
    }


    .feature {
        padding: 2em 0;
        background-color: #ff6d6d;
        transform: translateZ(0);
        .list{
            padding: 0 1em;
            .item {
                padding: 2em 1em;
                background-color: #fff;
                border-radius: 2em;
                box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.1);
                margin-bottom: 2em;
    
                .title {
                    font-family: 'Noto Sans JP';
                    font-style: normal;
                    font-weight: 900;
                    font-size: 22px;
                    line-height: 36px;
                    text-align: center;
                    margin-bottom: 1em;
                }
    
                .img {
                    width: 100%;
                    margin-bottom: 1em;
                }
    
                .text {
                    font-family: 'Noto Sans JP';
                    font-style: normal;
                    font-weight: 350;
                    font-size: 18px;
                    line-height: 31px;
                }
            }
        }
        
        .afterinfo{
            padding: 0 1em;
            font-family: 'Noto Sans JP';
            font-style: normal;
            font-weight: 350;
            font-size: 17px;
            line-height: 27px;
            /* または158% */
            text-align: center;

            color: #FFFFFF;
        }
        .line{
            width: 100%;
            height: 6px;
        }
        .download{
            padding: 0 1em;
            .text{
                font-family: 'Noto Sans JP';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 27px;
                text-align: center;
                color: #882525;
                padding: 1.5em;
            }
            .bt{
                background-color: #ffffff;
                border-radius: .75em;
                font-family: 'Noto Sans JP';
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 39px;
                text-align: center;
                padding: .5em;
                color: #F16969;
                display: block;
                text-decoration: none;
            }
        }
    }

    .infotext {
        padding: 60px 30px;
        display: block;
        color: #000;
        text-decoration: none;

        &.white {
            background-color: #fff;
            padding-bottom: 100px;
            backdrop-filter: blur(8px);
        }

        .title {
            margin-bottom: 30px;

            h1 {
                font-family: 'Noto Sans JP';
                font-style: normal;
                font-weight: 900;
                font-size: 37px;
                line-height: 47px;
                text-align: center;
                margin-bottom: 30px;
            }
        }

        .icon {
            display: flex;
            justify-content: space-around;
            margin-bottom: 60px;

            img {
                height: 30px;
            }
        }

        .text {
            line-height: 200%;
            font-size: 18px;
            font-weight: 600;
            line-height: 4.5vh;
        }

        .sample {
            display: block;
            width: 100%;
            object-fit: cover;
            margin: auto;
            margin-top: 50px;
            border-radius: 20px;
            box-shadow: 0 20px 20px rgba(0, 0, 0, 0.16);
        }

        .info{
            margin-top: 1em;
            font-family: 'Noto Sans JP';
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 37px;
            /* または246% */
            text-align: center;

            color: #000000;
        }
    }

    .q_and_a {

        background-color: #ff6d6d;
        padding: 2em 1em;
        color: #fff;

        .title{
            font-family: 'Noto Sans JP';
            font-style: normal;
            font-weight: 900;
            font-size: 37px;
            line-height: 47px;
            text-align: center;
            margin: 1em 0 2em ;
        }

        .list {
            .item {
                margin-bottom: 3em;
                > div{
                    display: flex;
                    margin-bottom: 1em;
                    &:first-child{
                        font-weight: 900;
                        font-size: 1.1em;
                    }
                    .icon{
                        color: #fff;
                        font-weight: 900;
                        font-size: 1.5em;
                        margin-right: .75em;
                    }
                }
                
            }

        }
    }


    .event_hold_map {
        border-radius: 50px;
        overflow: hidden;
        box-shadow: 0 5px 10px rgba(0, 0, 0, .16);
        position: relative;
        z-index: 1;
    }

}