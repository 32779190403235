@import './lib/PCScrollBar.module.scss';


.root {
    transform: translateZ(0px);
    margin: auto;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .box{

        transform: translateZ(0px);
        height: 100%;

        @include _pcscrollbar;
        overflow: hidden !important;
    }
}

@media only screen and (min-width: 500px) {

    .root {
        
        max-width: 500px;
    }
}
