@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@900&display=swap');

.root {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.16) !important;
    z-index: 1;
    padding-bottom: env(safe-area-inset-bottom);

    .navi{
        background-color: #fff !important;
        a{
            color: #ddd;

            .anchor{
                position: relative;
            }

            &.news{
                .icon{
                    position:relative;
                }
                .num{
                    position:absolute;
                    top: 0px;
                    left: calc(50% + 5px);
                    letter-spacing: -1px;
                    background-color: #ff0000;
                    border-radius: 100px;
                    display: flex;
                    width: 22px;
                    height: 22px;
                    align-items: center;
                    align-content: center;
                    justify-content: space-around;
                    > div{
                        color: #fff;
                        font-weight: 900;
                        font-size: 11px;
                        font-family: 'M PLUS Rounded 1c', sans-serif;
                    }
                }
            }
            &.disabled{
                opacity: .5;
                pointer-events: none;
            }
            
        }
    }
}