@import './lib/PCScrollBar.module.scss';

.root{

    @include _pcscrollbar;
    overflow-y: scroll;
    height: 100%;

    hr{
        width: 100%;
        height: 1px;
        background-color: rgba(255, 255, 255, .5);
        border: none;
    }

}
.map
{
    height: 100%;
    position: relative;
    .logo{
        position: absolute;
        top: 1em;
        left: 1em;
        z-index: 9999;
        background-color: #ffffffd8;
        padding: .5em;
        border-radius: 1000px;
        width: 3em;
        height: 3em;
        box-shadow: 0 5px 10px rgba(0,0,0,.16);
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 80%;
            display: block;
        }
    }
}
.queies_tag{
        
    position:absolute;
    left:0;
    right: 0;
    top: 82px;
    z-index: 99999;
    padding: 1em;
    padding-left: 20px;
    > div{
        background-color: #f1f1f1;
        border-radius: 1000px;
        padding: 5px 14px;
        font-size: .8em;
        margin-right: .5em;
        margin-bottom: .5em;
        font-weight: 900;
        color: #666;
        width: fit-content;
        display: inline-block;
    }
}

.queies_list{
        
    display: flex;
    padding: 1em;
    flex-direction: column;

    > div{
        display: flex;
        align-items: center;
        padding: 20px 14px;
        font-size: 1em;
        white-space: nowrap;
        margin-right: .5em;
        font-weight: 900;
        color: #000;

        svg{
            margin-right: 1em;
        }
    }
}
.new_shop{
    padding: 60px 20px;
    .title{
        font-weight: 900;
        font-size: 1.5em;
        margin-bottom: .6em;
    }
    .box{
        > div{
            padding-bottom: 40px;
            > ul{
                li{
                    background-color: rgb(218, 218, 218) !important;
                }
            }
        }
    }
    .item{
        width: 100%;
        text-decoration: none;
        color: #000;
        display: block;
        .thumb{
            width: 100%;
            vertical-align: top;
            border-radius: 1em;
            margin-bottom: .5em;
        }
        .name{
            font-size: 1.3em;
            font-weight: 900;
            text-align: left;
        }
        .desc{
            font-size: 1em;
            color: #717171;
            text-align: left;
        }
    }
}

.list{
    
    
    padding-left: 20px;
    white-space: nowrap;
    overflow-x:overlay;
    @include _pcscrollbar;
    
    .item{
        display: block;
        display: inline-block;
        width: 50%;
        max-width: 180px;
        margin-right: 20px;
        text-decoration: none;
        .head{
            border-radius: 20px 20px 0 0;
            overflow: hidden;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            img{
                width: 100%;
            }
        }
        .bottom{
            color: #fff;
            border-radius: 0 0 20px 20px;
            padding: 20px;
            background-color: #CB2D4A;
            .label{
                font-size: 20px;
            }
            .text{
                font-size: 16px;
            }
        }

        &.disable{
            opacity: 0.5;
            pointer-events: none;
        }
    }
}

.infotext{
    padding: 60px 30px;
    display: block;
    color: #000;
    text-decoration: none;

    &.white{
        background-color: #ffffffad;
        padding-bottom: 100px;
        backdrop-filter: blur(8px);
    }

    .title{
        margin-bottom: 30px;
        h1{
            font-weight: bold;
            line-height: 150%;
            font-size: 36px;
        }
    }
    .icon{
        display: flex;
        justify-content: space-around;
        margin-bottom: 60px;
        img{
            height: 30px;
        }
    }
    .text{
        line-height: 200%;
        font-size: 18px;
        font-weight: 600;
        line-height: 4.5vh;
    }

    .sample{
        display: block;
        width: 100%;
        object-fit: cover;
        margin: auto;
        margin-top: 50px;
        border-radius: 20px;
        box-shadow: 0 20px 20px rgba(0,0,0,0.16);
    }
}

.exp{

    position: relative;
    overflow: hidden;
    .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 20px 150px;
        justify-content: center;
        position: relative;
        z-index: 1;
        .download_bt{
            background-color: #000;
            border-radius: 100px;
            padding: 20px 40px;
            color: #fff;
            text-decoration: none;
            font-weight: 600;
            text-align: center;
            font-size: 16px;
        }
        .exp_bt{
            background-color: #ff6d6d;
            border-radius: 100px;
            padding: 20px 40px;
            color: #fff;
            text-decoration: none;
            font-weight: 600;
            text-align: center;
            font-size: 16px;
        }
    }
}

.bg2{
    width: 100%;
}


.menulist{
        
    padding: 1em;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .item{
        padding: 2em;
        background-color: #fff;
        box-shadow: 0px 0px .75em rgba(0, 0, 0, 0.16);
        margin-bottom: 15vh;
        border-radius: 1em;
        text-decoration: none;
        color: #000;

        &:last-child{
            margin-bottom: 0;
        }

        .row{
            display: flex;
            margin-bottom: 5vh;
            &:last-child{
                margin-bottom: 0;
            }
            .icon{
                width: 3.5em;
                margin-right: 2em;
            }
            > .text{
                > .title{
                    font-weight: 600;
                    font-size: 1.3em;
                }
                > .desc{
                    font-weight: 500;
                    color: #333;
                    font-size: .9em;
                    line-height: 4.5vh;
                }
            }
        }

        
    }
}

.download_app{
    position: fixed;
    bottom: 3.5em;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity .3s;
    display: flex;
    padding: 1em;
    pointer-events: none;
    >div{

        &:first-child{
            margin-right: .5em;
        }
        img{
            display: block;
            height: 10vh;
            max-width: 100%;

            &.android{
                opacity: .7;
            }
        }
    }
    
    &.show{
        opacity: 1;
        pointer-events: all;
    }
}

.event_hold_map{
    border-radius: 50px;
    overflow: hidden;
    box-shadow: 0 5px 10px rgba(0,0,0,.16);
    position: relative;
    z-index: 1;
}