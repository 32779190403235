@import './lib/PCScrollBar.module.scss';

.root {
    padding: 40px 30px;
    background-color: #f5f3ef;
    .logo {
        display: block;
        margin: 10px 0;
        height: 30px
    }

    .text {
        font-size: 14px
    }

    a {
        img {
            width: 30px;
            height: 30px
        }
    }
}