.root{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding-top: 2em;
    padding-left: 1em;
    padding-right: 1em;
    z-index: 9999;
    .searchbox{
        border-radius: 1000px;
        position: relative;
        .search_icon{
            position: absolute;
            right: 20px;
            top: 0;
            bottom: 0;
            margin: auto;
        }
        input{
            width: 100%;
            height: 50px;
            border: 1px solid #e5e5e5;
            border-radius: 1000px;
            padding: 0;
            padding-left: 1em;
            font-size: 1em;
            font-family: 'Noto Sans JP', sans-serif;
            background-color: #fff;
        }
        .close_bt{
            position: absolute;
            right: 20px;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }
    
}

.listRoot{
    padding: 1em;
    position: relative;
    z-index: 11111111;
    &.hide{
        opacity: 0;
        pointer-events: none;
    }

    .content{
        padding-top: 9.5em;

        .bar{
            position: absolute;
            top: 15px;
            left: 0;
            right: 0;
            margin: auto;
            border: none; 
            border-radius: 100px;
            height: 4px;
            width: 30px;
            background-color: rgb(208, 208, 208);
        }
        
        .list{
            padding-bottom: 20px;
            .no_result{
                display: flex;
                justify-content: center;
                min-height: 20vh;
                align-items: center;
            }
            .item{
                color: #000;
                text-decoration: none;
                padding-bottom: 20px;
                display: block;
                .row{
                    display: flex;
                    padding: 10px;
                    .icon{
                        border-radius: 1000px;
                        overflow: hidden;
                        width: 60px;
                        height: 60px;
                    }
                    .text{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding: 0 10px;

                        .name{
                            font-weight: 900;
                        }
                        .cate{
                            font-size: .8em;
                        }
                        .address{
                            font-size: .8em;
                        }
                    }
                }
                
                .menus{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-left: 15px;
                    overflow: scroll;
                    img{
                        margin-right: 5px;
                        width: 100px;
                        height: 100px;
                        object-fit: cover;
                    }
                }
            }
            .more{
                background-color: #fff;
                border-radius: 1000px;
                padding: 1em 2.5em;
                margin: auto;
                width: fit-content;
                margin-bottom: 2em;
                box-shadow: 0 5px 10px rgba(0,0,0,.16);
                font-size: .8em;
            }
        }
    }
    

    .bottom_sheet{
        padding-top: calc(100vh - 10em);
        background: none;
        pointer-events: none;
        
        >div{
            background-color: #fff;
            box-shadow: 0 0 25px rgba(0,0,0,.3);
            pointer-events: all;
            min-height: 100vh;
                overflow: scroll;    // iphone,safari pointer-events対策
                >div{
                    width: calc(100% + 1px);    // iphone,safari pointer-events対策
                }
        }
    }

}
