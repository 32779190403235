
.root{
    padding: 10px;
    padding-top: 70px;
    overflow: scroll;
    height: 100%;

    > div{
        margin: 10px 10px 40px;
        div{
            margin: 10px;
        }
    }

    .header{
        position: absolute;
        top:0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #fff;
        box-shadow: 0 10px 10px rgba(0,0,0,0.16);
        margin: 0;

        h1{
            display: block;
            margin: 10px;
        }
        svg{
            display: block;
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    h1{
        font-size: 24px;
    }
}