.carousel {
    overflow: hidden;

    .wrapper {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        position: fixed;
        transform-style: preserve-3d;
        backface-visibility: hidden;
        will-change: transform,transition;
        overflow: unset;
        transform: translate3d(0px, 0px, 0px);
        top: 0;
        bottom: 0;

        li{

            display: list-item;
            text-align: -webkit-match-parent;
            
            transform-style: preserve-3d;
            backface-visibility: hidden;


            flex: none;
            position: relative;
            width: 100%;
        }
    }
}