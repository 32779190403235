@import './lib/PCScrollBar.module.scss';

.root{

    @include _pcscrollbar;
    height: 100%;
    
    .logo{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2em;
        img{
            width: 10%;
            display: block;
        }

    }
}