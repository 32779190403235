.root{

    .item{
        display: flex;
        flex-direction: row;
        gap: 1em;
        margin-bottom: 2em;

        .row{
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .option{
            padding-top: .5em;
            label:first-child{
                margin-right: 1em;
            }
            &.disabled{
                color: #999;
            }
        }
        .time{
            flex-wrap: nowrap;
            flex: 1;
            > div{
                margin-bottom: 1em ;
                &:nth-last-child(2){
                    margin-bottom: 0;
                }
            }
        }
        .removebt{
            padding-left: .5em;
        }
        .addbt{
            padding-top: 1em;
            padding-right: .5em;
        }
    }
}
