
.root {
    background-color: #F4F4F4;
    padding: 2em 1em;
    > a {
        background-color: #fff;
        margin-bottom: 2em;
        display: block;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        text-decoration: none;
        padding: 1.5em 0;
        .imgs {
            display: flex;
            overflow: scroll;
            white-space: nowrap;
            padding-left: 1em;  
            img {
                width: 150px;
                height: auto;
                margin-right: .6em;
                display: inline-block;
            }
        }

        .box{
            padding: 1em;
            padding-bottom: 0;
            color: #000;
            .name{
                font-size: 1.3em;
                font-weight: bold;
                display: flex;
                align-items: center;
            }
            .category{
                font-size: .6em;
                color: #7F7F7F;
                background: #F4F4F4;
                border-radius: 60px;
                display: inline-block;
                padding: 0.4em .75em;
                margin-left: .5em;
            }
            .address{
                font-size: .9em;
                color: #000;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .distance{
                    font-size: 1.1em;
                    color: #000;
                    display: flex;
                    align-items: center;
                    img{
                        width: .9em;
                        height: auto;
                        margin-right: 0.5em;
                    }
                }
            }
            .request{
                font-size: 1.1em;
                color: #000;
                display: block;
                background: #ffa600;
                color: #fff;
                padding: .5em .75em;
                border-radius: .3em;
                text-decoration: none;
                font-weight: 900;
                width: fit-content;
                margin-top: .5em;
            }
            
        }

    }

    .moreButton {
        border-bottom: 1px solid #000;
        cursor: pointer;
        display: block;
        margin: 3em auto 1em;
        font-size: 16px;
        width: fit-content;
        &::before {
            content: "▼";
            margin-right: 0.5em;
        }
    }
}