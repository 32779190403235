.menu{
    position: absolute;
    left: auto;
    bottom: auto;
    z-index: 1200;
    top: 0;
    right: 0;
    
    
    &.show{
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        .box{
            background-color: #ffffff00;
            padding: .5em;
            border-radius: 1000px;
            width: 3em;
            height: 3em;
            display: flex;
            align-items: center;
            justify-content: center;
            

            .ico{
                opacity: 0;
                position: absolute;
            }
        }
        .bg{
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background-color: #0000002c;
        }
        .list{
            display: flex;
            opacity: 1;
            pointer-events: all;
        }
    }
    &.hide{
        .box{
            background-color: #ffffffd8;
            padding: .5em;
            border-radius: 1000px;
            width: 3em;
            height: 3em;
            box-shadow: 0 5px 10px rgba(0,0,0,.16);
            display: flex;
            align-items: center;
            justify-content: center;
            
            
            .ico{
                opacity: 1;
                position: initial;
            }
        }
        .bg{
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background-color: #00000000;
        }
        .list{
            opacity: 0;
            pointer-events: none;
            a{
                width: 1%;
            }
        }
        
    }
    .box{
        position: absolute;
        top: 1em;
        right: 1em;

        .ico{
            width: 32px;
            height: 32px;
            display: block;
        }

    }

    .list{

        position: absolute;
        top: 1em;
        right: 1em;
        width: fit-content;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        z-index: 999999;
        transition: all .3s ease-in-out;
        a{
            transition: all .3s ease-in-out;
            text-decoration: none;
            color: #000;
            display: flex;
            background-color: #fff;
            padding: .5em 1em;
            width: 100%;
            border-radius: 1000px;
            margin-bottom: .5em;
            box-shadow: 0 5px 10px rgba(0,0,0,.16);
            overflow: hidden;
            white-space: nowrap;
            svg{
                margin-right: .3em;
            }
        }
    }

}
