.root{

    font-family: Poppins, "Helvetica Neue", Helvetica, Arial, "Hiragino Sans", ヒラギノ角ゴシック, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", Roboto, メイリオ, Meiryo, "ＭＳ Ｐゴシック", sans-serif;
    display: flex;
    flex-direction: column;
    height: 100%;


    @mixin _button{
        background-color: #006BF9;
        color: #fff;
        padding: 5px 20px;
        border-radius: 6px;
        border: none;
        font-weight: 600;
        text-align: center;
        text-decoration: none;
    }
    button{
        @include _button;

        &:focus{
            background-color: #006BF9;
        }

        &.outline{
            background-color: #fff;
            color: #006BF9;
            border: 1px solid #006BF9;
        }
    }

    .header{
        position: relative;
        padding: 20px;
        .title{
            font-weight: 600;
            text-align: center;
        }
        .save_bt{
            position: absolute;
            right: 10px;
            top: 15px;
        }
        .back_bt{
            position: absolute;
            left: 10px;
            top: 15px;
            color: #006BF9;
            border:1px solid #006BF9;
            background-color: #fff;
        }
    }
    .scroller{
        overflow: scroll;
        padding: 10px;
        padding-bottom: 100px;
        
        > .item{
            margin-top: 20px;

            > .icon{
                width: 80px;
                height: 80px;
                border-radius: 10px;
                object-fit: cover;
            }
            > .photo{
                width: 100%;
                object-fit: cover;
            }
            
            > .label{
                font-weight: bold;
                font-size: 16px; 
                margin-bottom: 10px;
            }

            > .row{
                display: flex;
                align-items: center;
            }

            > .listitem{
                padding-bottom: 1em;
                > .listitem_label{
                }
                > .row{
                    display: flex;
                    // 隙間 gap
                    grid-gap: 10px;


                    > * {
                        white-space: nowrap;
                    }
                }
            }
        }
        .imgs{
            display: flex;
            flex-wrap: nowrap;
            overflow-x: scroll;
            .item{
                display: inline-block;
                width: 200px;
                height: 200px;
                margin-right: 1em;
                position: relative;
                img{
                    width: 200px;
                    height: 200px;
                    object-fit: cover;
                }

                .delete{
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-color: #000;
                    color: #fff;
                    padding: 0.5em;
                    cursor: pointer;
                }
            }
        }
        > .menus{
            > div{
                > .item{
                    display: flex;
                    margin-top: 15px;
                    > .row{
                        display: flex;
                        > .icon{
                            width: 50px;
                            height: 50px;
                            border-radius: 10px;
                            object-fit: cover;
                            margin-right: .5em;
                        }
                        > .text{

                        }
                    }


                    .trash{
                        margin-left: .5em;
                        color: #999;
                    }
                }
            }
        }
        > .links{
            > .item{
                margin-top: 15px;
                display: flex;


                img{
                    width: 1.5em;
                    height: 1.5em;
                    margin-right: .5em;
                }

                &.sns{
                    align-items: center;
                }


                &.web{
                    img {
                        margin-top: .5em;
                    }
                }

                > div:first-child{
                    flex: 1;
                    padding-right: 10px;
                }
                > div{
                    margin-bottom: 0;
                }
                .trash{
                    color: #999;
                }
                
            }
        }
        > .pr_video{
            padding: 1em;
            .video{
                width: 100%;
                height: 50vh;
                object-fit: cover;
                border-radius: 2em;
            }
            .none{
                background-color: #eee;
                border-radius: 2em;
                width: 100%;
                height: 50vh;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #999;
            }
        }

        .map{
            font-weight: bold;
            width: 100%;
            height: 250px;

            iframe{
                border: none;
            }
        }

        > .open_action{
            select{
                width: 100%;
                height: 40px;
                border-radius: 10px;
                border: 1px solid #ccc;
                padding: 0 10px;
                appearance: none;
                background-image: url("./icon/arrow-r.svg");
                background-repeat: no-repeat;
                background-position: center right 1em;
                background-size: 15px;
                padding-right: 20px;
            }
        }
    }
}